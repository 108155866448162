import { MatrixElement } from 'chartjs-chart-matrix';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  type ScriptableContext,
  Title,
  Tooltip,
} from 'chart.js';
import React, { type FC } from 'react';
import { t } from 'i18next';
import { getReadableUnitByName } from 'utils/formatters/units/getReadableUnitByName';
import { type HeatMapData } from 'interfaces/change/ChangeData.interface';
import { interpolateGnBu, interpolateYlOrRd } from 'd3-scale-chromatic';
import { Matrix } from 'utils/typedMatrixChart/typedMatrixChart';

ChartJS.register(
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  MatrixElement,
  ChartDataLabels
);

interface HeatMapProps {
  heatMapData?: HeatMapData[];
  unit?: string;
  positiveIsRed?: boolean;
}
export interface CategoricalMatrixDataPoint {
  x: string;
  y: string;
  value: number;
}
interface ChartData<T> {
  datasets: T[];
}

interface MatrixData<T> {
  label: string;
  data: T[];
  backgroundColor: string | ((context: ScriptableContext<'matrix'>) => string);
  borderColor: string | ((context: ScriptableContext<'matrix'>) => string);
  width: number | ((context: ScriptableContext<'matrix'>) => number);
  height: number | ((context: ScriptableContext<'matrix'>) => number);
  borderWidth: number | ((context: ScriptableContext<'matrix'>) => number);
}

// more help here on that link : https://codesandbox.io/s/react-typescript-forked-kffrov?file=/src/App.tsx:456-2341
const HeatMap: FC<HeatMapProps> = ({
  heatMapData,
  unit,
  positiveIsRed = true,
}) => {
  const modifiedData =
    heatMapData?.map((dataPoint, index) => ({
      x: dataPoint.day,
      y: dataPoint.time,
      value: dataPoint.value,
    })) ?? [];

  let minValue = Number.MAX_VALUE;
  let maxValue = Number.MIN_VALUE;

  for (const dataPoint of modifiedData) {
    if (dataPoint.value < minValue) {
      minValue = dataPoint.value;
    }
    if (dataPoint.value > maxValue) {
      maxValue = dataPoint.value;
    }
  }

  const isColorLight = (color: string): boolean => {
    // Attempt to extract RGB values from the color string
    const rgbMatches = color.match(/\d+/g);

    // Check if the match was successful
    if (rgbMatches && rgbMatches.length === 3) {
      const [r, g, b] = rgbMatches.map(Number);

      // Using the formula for luminance to find out if the color is light
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

      return luminance > 150;
    } else {
      return false;
    }
  };

  const getBackgroundColor = (value: number): string => {
    const normalizedValue = value >= 0 ? value / maxValue : value / minValue;
    const interpolatePositive = positiveIsRed
      ? interpolateYlOrRd
      : interpolateGnBu;
    const interpolateNegative = positiveIsRed
      ? interpolateGnBu
      : interpolateYlOrRd;

    return value >= 0
      ? interpolatePositive(normalizedValue)
      : interpolateNegative(normalizedValue);
  };

  const data: ChartData<MatrixData<CategoricalMatrixDataPoint>> = {
    datasets: [
      {
        label: 'My Matrix',
        data: modifiedData,
        backgroundColor: (context) => {
          if (context.raw) {
            const value = (context.raw as CategoricalMatrixDataPoint).value;
            return getBackgroundColor(value);
          } else {
            return 'rgba(50, 50, 50, 1)';
          }
        },
        borderColor(context) {
          return `rgb(0, 0, 0)`;
        },
        borderWidth: 0,
        width: ({ chart }) => (chart?.chartArea?.width ?? 0) / 7 - 1,
        height: ({ chart }) => (chart?.chartArea?.height ?? 0) / 24 - 1,
      },
    ],
  };

  const options = {
    animation: {
      duration: 0, // general animation time
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        color: (context: any) => {
          const color = getBackgroundColor(
            context.dataset.data[context.dataIndex].value
          );
          return isColorLight(color) ? '#6B7280' : '#fff';
        },
        borderWidth: 1,
        font: {
          weight: 'bold' as const,
          size:
            window.innerWidth >= 1200 ? 12 : window.innerWidth >= 1000 ? 10 : 8,
        },
        formatter(element: any, context: any) {
          return `${
            element.value.toFixed(element.value === 0 ? 3 : 2) as string
          } ${unit ? getReadableUnitByName(unit) : ''}`;
        },
      },
      tooltip: {
        enabled: false,
      },
    },

    scales: {
      x: {
        type: 'category' as const,
        labels: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
        ],
        position: 'top' as const,
        ticks: {
          callback: function (val: any, index: any, ticks: any) {
            return getLabelForValue(val);
          },
          color: '#001334',

          padding: 0,
          font: {
            family: 'CodecWarm' as const,
            size: 16,
            style: 'normal' as const,
            weight: '500' as const,
            lineHeight: '140%',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        type: 'category' as const,
        labels: [
          '23h',
          '22h',
          '21h',
          '20h',
          '19h',
          '18h',
          '17h',
          '16h',
          '15h',
          '14h',
          '13h',
          '12h',
          '11h',
          '10h',
          '09h',
          '08h',
          '07h',
          '06h',
          '05h',
          '04h',
          '03h',
          '02h',
          '01h',
          '00h',
        ],
        offset: true,
        ticks: {
          display: true,
          color: '#45526A',
          font: {
            family: 'CodecWarm' as const,
            size: 12,
            style: 'normal' as const,
            weight: '400' as const,
            lineHeight: '140%',
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };

  function getLabelForValue(day: number): string {
    const days: Record<number, string> = {
      0: t('common.days.monday'),
      1: t('common.days.tuesday'),
      2: t('common.days.wednesday'),
      3: t('common.days.thursday'),
      4: t('common.days.friday'),
      5: t('common.days.saturday'),
      6: t('common.days.sunday'),
    };
    return days[day].toUpperCase();
  }

  return (
    <div>
      <Matrix
        id="matrixChart"
        options={options}
        data={data}
        className={'max-w-[1380px] w-[95vw]'}
      />
    </div>
  );
};
export default HeatMap;
