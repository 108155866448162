import { type FC, forwardRef, useMemo } from 'react';

import useChart from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { getReadableUnitByName } from 'utils/formatters/units/getReadableUnitByName';

interface OrganizationalSavingsChartProps {
  id?: string;
  momentChange?: string;
  dataGrouping?: [string, number[] | null][] | undefined;
  unit?: string;
  data?: any[];
  futureData?: any[] | null;
  targetData?: any[] | null;
  changesData?: string[];
  xAxisEvents?: Highcharts.XAxisEventsOptions | undefined;
  savingsTarget?: number;
}

// interface SavingsOption {
//   x: number;
//   y: number;
//   impactPerDay: number;
//   time: string;
// }

const EnergyEvolutionChart: FC<OrganizationalSavingsChartProps> = forwardRef(
  (
    {
      unit = '',
      dataGrouping,
      data,
      xAxisEvents,
      futureData,
      targetData,
      savingsTarget = 0,
    },
    _ref
  ) => {
    const { defaultOptions, labelFormatter } = useChart({
      chartData: data,
      unit: getReadableUnitByName(unit),
      type: 'excess_cusum',
    });
    function pointFormatter(this: Highcharts.Point): string {
      const measuredConsumption = `<b>Measured consumption:</b> ${'365.000'}<br/>`;
      const targetThisYear = `<b>Target this year:</b> ${'350.000'}<br/>`;
      const target2030 = `<b>Target 2030:</b> ${'220.000'}<br/>`;
      const gapBetweenTarget = `<b>Gap between targets:</b> ${'-130.000'}<br/>`;

      return `${measuredConsumption}${targetThisYear}${target2030}${gapBetweenTarget}`;
    }

    const { theme } = useTheme();

    const future = useMemo(() => {
      const currentYear = data?.find(
        (obj) => obj.year === new Date().getUTCFullYear().toString()
      );
      return [currentYear, ...(futureData ?? [])];
    }, [data, futureData]);

    const options: Highcharts.Options = {
      ...defaultOptions,
      rangeSelector: {
        enabled: false,
      },
      tooltip: {
        ...defaultOptions.tooltip,
        formatter(
          this: Highcharts.TooltipFormatterContextObject,
          tooltip: Highcharts.Tooltip
        ): string | string[] {
          tooltip.update({
            shadow: {
              color: 'rgba(0, 0, 0, 0.08)', // Color without opacity
              offsetX: 0, // Horizontal offset
              offsetY: 4, // Vertical offset
              // opacity: 0.08, // Opacity from the rgba value
              width: 5, // Blur radius
            },
            backgroundColor: '#EEE',
            borderColor: '#EEE',
            style: {
              color: '#45526A',
              fontSize: '12px',
              fontWeight: '400',
            },
          });

          return tooltip.defaultFormatter.call(this, tooltip);
        },
      },
      xAxis: {
        labels: {
          formatter(this) {
            const value = this.value;
            const date = new Date(value).getUTCFullYear();
            if (date === new Date().getUTCFullYear()) {
              return 'NOW';
            }
            return date.toString();
          },
        },
        plotLines: [
          {
            color: 'rgba(82, 43, 137, 0.24)',
            width: 5,
            value: new Date(
              new Date().getUTCFullYear().toString()
            ) as unknown as number,
          },
        ],
        events: {
          ...xAxisEvents,
        },
      },
      yAxis: {
        labels: {
          align: 'right',
          formatter: labelFormatter,
        },
        opposite: false,
      },

      navigator: {
        enabled: false,
      },

      series: [
        {
          name: 'Measured consumption',
          type: 'line',
          data: data
            ? data.map((ts, index) => ({
                x: new Date(ts.year).getTime(),
                y: ts.value,
                // dashStyle:
                //   new Date(ts.time).getFullYear() > new Date().getFullYear()
                //     ? 'Dash'
                //     : 'Solid',
              }))
            : [],
          color: theme.colors.primary,
          marker: {
            enabled: true,
            radius: 5,
          },
          tooltip: {
            pointFormatter,
          },
        },
        {
          name: 'Planned projects',
          type: 'line',
          data: future
            ? future.flatMap((ts, index) => ({
                x: new Date(ts?.year).getTime(),
                y: ts.value,
              }))
            : [],
          color: theme.colors['gray-50'],

          dashStyle: 'Dash',
          marker: {
            symbol: 'circle',
            enabled: true,
            radius: 5,
          },
          tooltip: {
            pointFormatter,
          },
        },
        {
          name: 'Target 2030 according to CRREM',
          type: 'line',
          data: targetData
            ? targetData.flatMap((ts, index) => ({
                x: new Date(ts.year).getTime(),
                y: ts.value,
                color: theme.colors.error,
              }))
            : [],
          color: theme.colors.error,
          dashStyle: 'Dash',
          marker: {
            symbol: 'circle',
            enabled: true,
            radius: 5,
          },
          tooltip: {
            pointFormatter,
          },
        },
      ],
    };

    return (
      <>
        {options && (
          <div>
            <span>
              <span className="font-bold">{'CO₂ intensity'}</span> {' kWh/m²'}
            </span>
            <HChart options={options} />
          </div>
        )}
      </>
    );
  }
);
export default EnergyEvolutionChart;
