import {
  useFetchBuildingSankey,
  useFetchBuildingView,
  useFetchMeterTimeseries,
} from 'api/assets/buildingViewService';
import { type FC, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BuildingCard } from './components/BuildingCard';
import { BuildingOverallPerformance } from './components/BuildingOverallPerformance';
import { BuildingSankeyChart } from './charts/BuildingSankeyChart';
import { BuildingNeedAttentionTable } from './tables/BuildingNeedAttentionTable';
import { MostImportantChangesTable } from './tables/MostImportantChangesTable';
import { CardContainer } from 'layout/Card';
import { SingleSelectInput } from '@components/inputs/SelectInput/SingleSelectInput';
import { BuildingSankeyButton } from './components/BuildingSankeyButton';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
// import buildingViewMockData from './mock/buildingViewMock.json';
import { SelectedMeterTag } from './components/SelectedMeterTag';
// import { ChartButton } from '@components/charts/ChartButton';
// import { t } from 'i18next';
// import { ProjectMeasuredVsExpectedChart } from 'pages/Management/ProjectDetailsPage/charts/ProjectMeasuredVsExpectedChart';
// import { ExcessConsumptionCuSUMChart } from 'pages/Changes/ChangeDetailsPage/components/charts/ExcessConsumptionCuSUMChart';
import { BuildingSankeyAlternativeMeterChart } from './charts/BuildingSankeyAlternativeMeterChart';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { useNotFoundPage } from 'hooks/useNotFoundPage';
import { type SankeyButtonsData } from 'interfaces/assets/Building.interface';
import { BuildingMeterChart } from './charts/BuildingMeterChart';
import { parseUnitType } from 'utils/formatters/units/getReadableUnitByName';

const SANKEY_BUTTONS_ORDER = ['ELECTRICITY', 'GAS', 'WATER', 'THERMAL_ENERGY'];

export enum GRADE {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NONE = 'NONE',
}

// const CHART_BUTTON_VALUES = {
//   MEASURED: 'measured',
//   CUSUM: 'cusum',
// };

export interface ActionItem {
  name: string;
  isActionRequired: boolean;
}

const BuildingViewPage: FC = () => {
  const { id } = useParams();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const { data, isFetching, error, isError } = useFetchBuildingView(id);
  const { validateAndRedirectToNotFound } = useNotFoundPage();
  const [isActionRequired, setIsActionRequired] = useState<ActionItem[]>([]);
  const [buttonsData, setButtonsData] = useState<SankeyButtonsData[]>([]);
  const [chartUnitType, setChartUnitType] = useState({});
  const [selectedSankeyDropdownOption, setSelectedSankeyDropdownOption] =
    useState('year');

  const [selectedSankeyOption, setSelectedSankeyOption] = useState(
    UtilityTypeEnum.ELECTRICITY as string
  );

  const { data: buildingChartsData } = useFetchBuildingSankey(
    id,
    selectedSankeyOption,
    selectedSankeyDropdownOption
  );

  // const [activeChart, setActiveChart] = useState(CHART_BUTTON_VALUES.MEASURED);

  const [selectedMeter, setSelectedMeter] = useState<{
    id?: number;
    name?: string;
  }>({
    id: undefined,
    name: undefined,
  });
  const { data: meterTimeseriesData } = useFetchMeterTimeseries(
    selectedMeter?.id
  );

  const sankeyDropdownOptions = [
    { value: 'year', name: 'Last year' },
    { value: 'month', name: 'Last month' },
  ];

  const sortButtons = (a: string, b: string): number => {
    const indexA = SANKEY_BUTTONS_ORDER.indexOf(a);
    const indexB = SANKEY_BUTTONS_ORDER.indexOf(b);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) {
      return -1;
    }

    if (indexB !== -1) {
      return 1;
    }

    return a.localeCompare(b);
  };

  const sortedButtons = useMemo(() => {
    const utilityButtons = data?.meters_utility_type;
    if (utilityButtons) {
      return utilityButtons.sort(sortButtons);
    }
    return [];
  }, [data]);

  // const buttonList = [
  //   {
  //     label: t('anomaliesDetailsPage.buttons.measuredVsExcepted'),
  //     value: CHART_BUTTON_VALUES.MEASURED,
  //     tooltip: t('anomaliesDetailsPage.toolTips.measuredVsExcepted'),
  //   },
  //   {
  //     label: 'CuSUM',
  //     value: CHART_BUTTON_VALUES.CUSUM,
  //     tooltip: t('anomaliesDetailsPage.toolTips.CuSUM'),
  //   },
  // ];

  const handleSelectMeter = ({
    meterId,
    meterName,
  }: {
    meterId: number;
    meterName: string;
  }): void => {
    if (selectedMeter.id === meterId) {
      setSelectedMeter({ id: undefined, name: undefined });
      return;
    }
    setSelectedMeter({ id: meterId, name: meterName });
  };

  useEffect(() => {
    validateAndRedirectToNotFound(isError, error);
  }, [isError, error]);

  useMemo(() => {
    if (!buildingChartsData?.asset_buttons) {
      return;
    }
    const buttons = buildingChartsData.asset_buttons;
    setButtonsData(buttons);
    buttons.forEach((button) => {
      for (const [key, value] of Object.entries(button)) {
        setChartUnitType((prev) => ({
          ...prev,
          [key]: parseUnitType(value.unit),
        }));
      }
    });
  }, [buildingChartsData]);

  return (
    <div className="flex flex-col gap-4">
      {/* HEADER */}
      <div className="flex gap-4">
        <BuildingCard
          asset={data?.asset}
          isFetching={isFetching}
          isActionRequired={isActionRequired}
        />
        <div className="w-3/12">
          <BuildingOverallPerformance
            building={data?.asset_impact}
            renewableEnergy={data?.renewable_energy}
            isFetching={isFetching}
            setIsActionRequired={setIsActionRequired}
          />
        </div>
      </div>
      {/* TABLES */}
      <div className="flex gap-4">
        {/* TOP CHANGES TABLE */}
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full justify-between items-center my-2">
            <h3 className="font-bold uppercase">TOP 3 DETECTED CHANGES</h3>
            <Link
              to={`/app/operations/anomalies?asset=${id ?? ''}`}
              className="text-base mr-6 text-tertiary-dark"
            >
              See all
            </Link>
          </div>
          <MostImportantChangesTable
            buildingId={id}
            setIsActionRequired={setIsActionRequired}
          />
        </div>
        {/* NEED ATTENTION PROJECTS TABLE */}
        {hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.list) ? (
          <div className="flex w-full flex-col gap-4">
            <div className="flex justify-between items-center my-2">
              <h3 className="font-bold uppercase">Projects</h3>
              <Link
                to={`/app/management/projects?asset=${id ?? ''}`}
                className="text-base mr-6 text-tertiary-dark"
              >
                See all
              </Link>
            </div>
            <BuildingNeedAttentionTable
              buildingId={id}
              setIsActionRequired={setIsActionRequired}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <CardContainer gap={6}>
        <div className="flex justify-between items-center">
          <h3 className="font-bold uppercase">ENERGY OVERVIEW</h3>
          <div className="flex gap-4 items-center">
            <p>Showing</p>
            <SingleSelectInput
              options={sankeyDropdownOptions}
              value={selectedSankeyDropdownOption}
              onChange={(value) => {
                setSelectedSankeyDropdownOption(value as string);
              }}
              maxWidth="250px"
              placeholder="Select a date"
              removable={false}
            />
          </div>
        </div>
        <div className="flex gap-2">
          {sortedButtons?.map((utilityType) => {
            const parsedUtility = utilityType.toLowerCase();

            const matchingButton = buttonsData.find(
              (button) => Object.keys(button)[0] === parsedUtility
            );

            const value = matchingButton
              ? matchingButton[parsedUtility].energy_use
              : undefined;

            const percentage = matchingButton
              ? matchingButton[parsedUtility].percentage
              : undefined;

            return (
              <BuildingSankeyButton
                key={utilityType}
                id={utilityType}
                isSelected={selectedSankeyOption === utilityType}
                handleSelection={setSelectedSankeyOption}
                utilityType={
                  UtilityTypeEnum[utilityType as keyof typeof UtilityTypeEnum]
                }
                value={value}
                percentage={percentage}
                unit={
                  chartUnitType?.[
                    parsedUtility as keyof typeof chartUnitType
                  ] ?? ''
                }
              />
            );
          })}
        </div>
        {buildingChartsData?.use_alternative_chart ? (
          // ALTERNATIVE CHART
          <BuildingSankeyAlternativeMeterChart
            key={selectedSankeyOption}
            data={buildingChartsData?.asset_sankey?.meters}
            barOnClick={handleSelectMeter}
            utilityType={selectedSankeyOption}
            unit={
              chartUnitType?.[
                selectedSankeyOption?.toLowerCase() as keyof typeof chartUnitType
              ] ?? ''
            }
          />
        ) : (
          // SANKEY CHART
          <BuildingSankeyChart
            data={buildingChartsData?.asset_sankey}
            nodeOnClick={handleSelectMeter}
            unit={
              chartUnitType?.[
                selectedSankeyOption?.toLowerCase() as keyof typeof chartUnitType
              ] ?? ''
            }
          />
        )}
        {/* METER SELECTED CHART */}
        <SelectedMeterTag meter={selectedMeter} />

        {selectedMeter?.id && (
          <div>
            {/* <ChartButton
              buttonList={buttonList}
              activeChart={activeChart}
              handleActiveChart={setActiveChart}
              isLoading={false}
            /> */}
            {/* {activeChart === CHART_BUTTON_VALUES.MEASURED && ( */}
            <BuildingMeterChart
              data={meterTimeseriesData ? meterTimeseriesData.time_series : []}
              unit={meterTimeseriesData?.unit}
            />
            {/* )} */}
            {/* {activeChart === CHART_BUTTON_VALUES.CUSUM && (
              <ExcessConsumptionCuSUMChart
                data={
                  meterTimeseriesData ? meterTimeseriesData.time_series : []
                }
                unit=" "
              />
            )} */}
          </div>
        )}
      </CardContainer>
    </div>
  );
};
export default BuildingViewPage;
