import { IconTable } from '@components/table/IconTable';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { useFetchBuildingTopChanges } from 'api/changes/changesServices';
import { CardContainer } from 'layout/Card';
import { type FC, useEffect, useMemo, useState } from 'react';
import useGetMostImportantChangesTableColumns from './useGetMostImportantChangesTableColumns';
import clsx from 'clsx';
import { SadFaceIcon } from '@components/icons/FaceIcon';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { useNavigate } from 'react-router-dom';
import { type Change } from 'interfaces/change/ChangeData.interface';
import { type ActionItem } from 'pages/BuildingViewPage/BuildingViewPage';

interface MostImportantChangesTableProps {
  buildingId?: string;
  setIsActionRequired: React.Dispatch<React.SetStateAction<ActionItem[]>>;
}

const MostImportantChangesTable: FC<MostImportantChangesTableProps> = ({
  buildingId,
  setIsActionRequired,
}) => {
  const [hoveredId, setHoveredId] = useState<number | undefined>();
  const { theme } = useTheme();
  const { data, isFetching } = useFetchBuildingTopChanges(buildingId);
  const { columns } = useGetMostImportantChangesTableColumns();
  const navigate = useNavigate();

  const effectiveness = useMemo(() => {
    const meters = data?.change_detections;
    if (meters && meters.length > 0) {
      return !meters.some((meter) => meter.impact_average_diff_euro > 50);
    }
    return undefined;
  }, [data]);

  useEffect(() => {
    setIsActionRequired((prev) => {
      const updatedArray = prev.filter(
        (item) => item.name !== 'most_important_changes'
      );
      return [
        ...updatedArray,
        {
          name: 'most_important_changes',
          isActionRequired:
            effectiveness !== undefined ? !effectiveness : false,
        },
      ];
    });
  }, [effectiveness]);

  const navigateToChange = (change: Change): void => {
    if (change) navigate(`/app/operations/anomalies/details/${change.id}`);
  };

  return (
    <div
      className={clsx(
        'rounded-lg',
        effectiveness !== undefined
          ? effectiveness
            ? 'border border-success'
            : 'border border-error'
          : 'border-gray-10'
      )}
    >
      <CardContainer>
        <div className="flex items-center justify-between py-2">
          <div className="flex items-center gap-4">
            <h3 className="font-bold text-base">Most important</h3>
            <TooltipIconComponent content="The 3 most significant changes in consumption pattern that have been identified linked to this building" />
          </div>
          {effectiveness !== undefined && !effectiveness && <SadFaceIcon />}
        </div>
        <IconTable
          minHeight={200}
          skeletonAmountWhenLoading={3}
          isFetching={isFetching}
          enableRowBorder={false}
          stripped={false}
          hoverColor={theme.colors['primary-lighter']}
          hoveredId={hoveredId}
          onRowHover={setHoveredId}
          enableRowRoundedBorder={true}
          data={data?.change_detections ?? []}
          columns={columns}
          onClickRow={navigateToChange}
          emptyTableMessage="No important changes detected"
        />
      </CardContainer>
    </div>
  );
};
export default MostImportantChangesTable;
