import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import Tippy from '@tippyjs/react';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAddress } from 'utils/formatters/string/stringFormatter';
import { getReadableUnitByName } from 'utils/formatters/units/getReadableUnitByName';
import { getUtilityTypeReadableName } from 'utils/utilityTypeUtils/utilityTypeUtils';

const useGetMetersTableColumn = ({
  createTableActions,
}: {
  createTableActions: (info: CellContext<any, any>) => ReactNode;
}): any => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('name', {
      header: () => t('assetsPage.table.meterName'),
      cell: (info) => (
        <Tippy content={info.getValue()}>
          <span className="block text-ellipsis overflow-hidden truncate phone:max-w-[100px] tablet:max-w-[150px] laptop:max-w-[170px] desktop:max-w-[250px] desktop-l:max-w-[300px]">
            {info.renderValue()}
          </span>
        </Tippy>
      ),
    }),
    columnHelper.accessor('description', {
      header: () => t('assetsPage.table.description'),
      cell: (info) => (
        <span className="truncate block overflow-hidden phone:max-w-[100px] laptop:max-w-[130px]">
          {info.renderValue() ?? '-'}
        </span>
      ),
    }),
    columnHelper.accessor('submeter', {
      header: () => t('assetsPage.table.meterType'),
      cell: (info) => 'Main meter' ?? '-',
    }),
    columnHelper.accessor('assets', {
      header: () => t('assetsPage.table.location'),
      cell: (info) => {
        const assets = info.renderValue();
        const address = assets?.[0]?.address;
        return (
          <Tippy content={formatAddress(address)} disabled={!address}>
            <span className="capitalize truncate block phone:max-w-[100px] desktop:max-w-[130px]">
              {address ? formatAddress(address) : '-'}
            </span>
          </Tippy>
        );
      },
    }),
    columnHelper.accessor('utility_type', {
      header: () => t('assetsPage.table.utilityType'),
      cell: (info) => getUtilityTypeReadableName(info.renderValue()) ?? '-',
    }),
    columnHelper.accessor('unit_type', {
      header: () => t('assetsPage.table.unit'),
      cell: (info) => getReadableUnitByName(info.renderValue()) ?? '-',
    }),
    columnHelper.accessor('submeter', {
      id: 'submeter',
      header: () => t('assetsPage.table.subMeter'),
      cell: (info) => {
        if (info.renderValue() === true) return 'Yes';
        return 'No';
      },
    }),
    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info),
      size: 25,
    }),
  ];

  return {
    columns,
  };
};
export default useGetMetersTableColumn;
