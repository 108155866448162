import HChart from '@components/HChart/HChart';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import SankeyModule from 'highcharts/modules/sankey';
import type Highcharts from 'highcharts';
import {
  type BuildingSankey,
  type SankeyLinks,
  type SankeyNodes,
} from 'interfaces/assets/Building.interface';
// import sankeyMock from '../../mock/sankeyMock.json';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineRemove } from 'react-icons/md';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import {
  convertToPercentageParsedString,
  formatDecimalSeparator,
} from 'utils/formatters/number/numberUtils';
import { PiArrowDownFill, PiArrowUpFill } from 'react-icons/pi';

interface BuildingSankeyChartProps {
  data?: BuildingSankey;
  unit: string;
  nodeOnClick: ({
    meterId,
    meterName,
  }: {
    meterId: number;
    meterName: string;
  }) => void;
}
interface SankeyPoint {
  fromNode?: {
    category: string;
    name: string;
  };
  toNode?: {
    name: string;
    category: string;
  };
  name?: string;
  weight?: number;
  sum?: number;
}

interface CustomNodePoint extends Highcharts.Point {
  custom?: {
    meterId?: number;
  };
}

type HiddenConnectionsType = Record<string, SankeyLinks[]>;

const getNodeColor = (color: string, id?: number | string): string => {
  if (id === 'Non-measured consumption') {
    return '#D9D9D9';
  }
  return color === 'green' ? '#8CD134' : '#E92252';
};

const BuildingSankeyChart: FC<BuildingSankeyChartProps> = ({
  data,
  unit,
  nodeOnClick,
}) => {
  const { theme } = useTheme();
  const [expandedNode, setExpandedNode] = useState<string | undefined>(
    undefined
  );
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);

  const [nodePositions, setNodePositions] = useState<
    Record<string, { x: number; y: number }>
  >({});
  const [everyNodePositions, setEveryNodePositions] = useState<
    Record<string, { x: number; y: number }>
  >({});

  const hiddenConnections = useMemo<HiddenConnectionsType | undefined>(() => {
    if (!data) return {};
    const highestLevelNode = data.highest_level_node;
    if (highestLevelNode <= 1) {
      return {};
    }
    const highest = data?.links?.filter(
      (item) => (item.level || 0) === highestLevelNode
    );
    if (highest) {
      const groupedByParent = highest.reduce(
        (acc: HiddenConnectionsType, link) => {
          const parentId = link.from;
          if (!acc[parentId]) {
            acc[parentId] = [];
          }
          acc[parentId].push(link);
          return acc;
        },
        {}
      );
      return groupedByParent;
    }
  }, [data]);

  const visibleData = useMemo(() => {
    if (!data) return [];
    const highestLevelNode = data.highest_level_node;
    const parsedLevelNode = highestLevelNode > 1 ? highestLevelNode : 100;

    const sankeyData =
      data?.links
        ?.filter((item) => (item.level || 0) < parsedLevelNode)
        ?.sort((a, b) => b.weight - a.weight) ?? [];
    if (expandedNode && hiddenConnections?.[expandedNode] && sankeyData) {
      const filteredData = sankeyData.filter(
        (node) => node.from !== expandedNode && node.to !== null
      );
      const filteredHiddenConnections =
        hiddenConnections[expandedNode].sort((a, b) => b.weight - a.weight) ??
        [];

      return [...filteredData, ...filteredHiddenConnections];
    }
    return sankeyData;
  }, [data, hiddenConnections, expandedNode]);

  const updateChartData = useCallback(() => {
    if (chart) {
      chart.series[0].setData(visibleData);
    }
  }, [data, visibleData]);

  const handleExpandNode = (nodeId: string): void => {
    if (expandedNode === nodeId) {
      setExpandedNode(undefined);
    } else {
      setExpandedNode(nodeId);
    }
    updateChartData();
  };

  const chartNodes = useMemo(() => {
    if (data?.nodes) {
      const nodes: SankeyNodes[] = data.nodes.map((node) => ({
        ...node,
        color: getNodeColor(node.color, node.id),
        custom: {
          meterId: node.meter_id,
        },
      }));
      return nodes;
    }
    return [];
  }, [data, hiddenConnections, expandedNode]);

  const options: Highcharts.Options = {
    chart: {
      type: 'sankey',
      spacingTop: 25,
      spacingBottom: 25,
      spacingLeft: 25,
      spacingRight: 25,
      events: {
        render(this: Highcharts.Chart) {
          setChart(this);
        },
        redraw(this: Highcharts.Chart) {
          setChart(this);
        },
      },
    },
    xAxis: {
      scrollbar: {
        enabled: false,
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      sankey: {
        events: {
          click(e) {
            const point = e.point as CustomNodePoint;
            const meterId = point?.custom?.meterId;
            if (meterId) {
              const meterName = e.point.name;
              nodeOnClick({ meterId, meterName });
            }
          },
        },
        linkColorMode: 'to',
        minLinkWidth: 1,
        nodeWidth: 35,
        nodeAlignment: 'center',
        nodePadding: 30,
        tooltip: {
          nodeFormatter() {
            return `<b>${this.category}:</b></br>
          ${formatDecimalSeparator({
            value: (this as any).sum,
            decimalScale: 2,
          })} ${unit}
            `;
          },
          pointFormatter() {
            const point = this as unknown as SankeyPoint;

            return point?.fromNode && point?.toNode && point?.weight && point
              ? `<b>${point.fromNode.name}</b> → <b>${
                  point.toNode.name
                }</b> <br>
                <b>${point.fromNode.category}</b> → <b>${
                  point.toNode.category
                }</b> <br>
                ${formatDecimalSeparator({
                  value: point.weight,
                  decimalScale: 2,
                })} ${unit}`
              : `<b>${point?.name ?? ''}</b>: <br>${point?.sum ?? 0} ${unit}`;
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: theme.colors['gray-25'],
    },

    series: [
      {
        keys: ['from', 'to', 'weight'],
        data: visibleData ?? [],
        type: 'sankey',
        name: 'Flow',
        linkOpacity: 0.25,
        curveFactor: 0.4,
        nodes: chartNodes,
        dataLabels: {
          align: 'right',
          x: -40,
          useHTML: true,
          nodeFormatter() {
            if ((this.point as any).column === 0) {
              return `<span style="position: relative; left: 40px;">${
                this.key as string
              }</span>`;
            }
            return this.key;
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (!chart) {
      return;
    }

    const updatePositions = (): void => {
      const hasChildrenPositions: Record<string, { x: number; y: number }> = {};
      const nodePositions: Record<string, { x: number; y: number }> = {};
      const series = chart?.series?.[0];
      if (!series) return;

      (series as any).nodes.forEach((node: any) => {
        const nodeHeight = node.shapeArgs?.height ?? 1;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const x = node.nodeX + chart.plotLeft;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const y = node.nodeY + chart.plotTop + nodeHeight / 2;

        nodePositions[node.id] = { x, y };
        if (!hiddenConnections?.[node.id]) {
          return;
        }

        hasChildrenPositions[node.id] = { x, y };
      });

      setNodePositions(hasChildrenPositions);
      setEveryNodePositions(nodePositions);
    };
    setTimeout(updatePositions, 0);

    (chart as any)?.addEvent?.('redraw', updatePositions);
    return () => {
      if (chart) {
        (chart as any)?.removeEvent?.('redraw', updatePositions);
      }
    };
  }, [chart, hiddenConnections, expandedNode]);

  return (
    <div className="relative w-full h-96" id="building-sankey">
      <HChart options={options} modules={[SankeyModule]} callback={setChart} />
      {chartNodes?.length > 0 &&
        everyNodePositions &&
        chartNodes.map((node) => {
          const nodePosition = everyNodePositions?.[node.id];
          if (!nodePosition || !node.percentage) return <></>;

          return (
            <div
              key={node.id}
              className="absolute transform -translate-y-1/2 rounded-full p-1 pointer-events-none"
              style={{
                left: `${nodePosition.x - 3}px`,
                top: `${nodePosition.y}px`,
                zIndex: 1,
              }}
            >
              <div className="w-[33px] h-[14px] text-[10px] font-bold bg-white flex items-center rounded-sm justify-evenly">
                <p>
                  {convertToPercentageParsedString(Math.abs(node.percentage), {
                    decimalScale: 1,
                    clampPercentage: false,
                  })}
                </p>
                {node.color === theme.colors.success ? (
                  <PiArrowDownFill fill={node.color} size={14} />
                ) : (
                  <PiArrowUpFill fill={node.color} size={14} />
                )}
              </div>
            </div>
          );
        })}
      {Object.entries(nodePositions).map(([nodeId, position]) => (
        <button
          key={nodeId}
          className="absolute transform -translate-y-1/2 rounded-full p-1"
          style={{
            left: `${position.x + 35}px`,
            top: `${position.y}px`,
            zIndex: 1000,
          }}
          onClick={() => {
            handleExpandNode(nodeId);
          }}
          title={`${expandedNode === nodeId ? 'Collapse' : 'Expand'} ${nodeId}`}
        >
          <div
            className={`w-[20px] h-[20px] leading-none border flex items-center justify-center rounded-full bg-white shadow-lg transition-transform`}
          >
            {expandedNode === nodeId ? <MdOutlineRemove /> : <BsThreeDots />}
          </div>
        </button>
      ))}
    </div>
  );
};
export default BuildingSankeyChart;
