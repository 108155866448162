import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { type FC } from 'react';
import {
  getUtilityIcon,
  getUtilityTypeReadableName,
} from 'utils/utilityTypeUtils/utilityTypeUtils';
import { PiArrowDownFill, PiArrowUpFill } from 'react-icons/pi';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { SankeyButton } from './styled';
import {
  convertToPercentageParsedString,
  formatDecimalSeparator,
} from 'utils/formatters/number/numberUtils';
import { parseUnitType } from 'utils/formatters/units/getReadableUnitByName';

interface BuildingSankeyButtonProps {
  id: string;
  utilityType: UtilityTypeEnum;
  value?: number;
  percentage?: number;
  unit?: string;
  isSelected: boolean;
  handleSelection: (id: string) => void;
}

const BuildingSankeyButton: FC<BuildingSankeyButtonProps> = ({
  id,
  utilityType,
  value,
  percentage,
  unit,
  isSelected,
  handleSelection,
}) => {
  const { theme } = useTheme();
  const parsedValue = value
    ? formatDecimalSeparator({
        value,
        decimalScale: utilityType === UtilityTypeEnum.WATER ? 1 : 0,
      })
    : null;
  const parsedUnit = parseUnitType(unit);
  return (
    <SankeyButton
      $isSelected={isSelected}
      onClick={() => {
        handleSelection(id);
      }}
      className="min-w-[200px]"
    >
      <div className="flex gap-2.5 text-base items-center">
        <div>{getUtilityIcon(utilityType, theme.colors['gray-50'])}</div>
        <div>{getUtilityTypeReadableName(utilityType)}</div>
      </div>
      <div className="flex items-center gap-2 justify-between">
        <p className="text-lg font-bold">{`${parsedValue ?? '-'} ${
          parsedValue ? parsedUnit : '-'
        }`}</p>
        <p>
          {percentage ? (
            <span className="flex items-center">
              {percentage < 0 ? (
                <PiArrowUpFill fill={'#E92252'} size={14} />
              ) : (
                <PiArrowDownFill fill="rgba(140, 209, 52, 1)" size={20} />
              )}
              <p
                className="text-lg"
                style={{
                  color: percentage < 0 ? '#E92252' : 'rgba(140, 209, 52, 1)',
                }}
              >
                {convertToPercentageParsedString(Math.abs(percentage), {
                  clampPercentage: false,
                })}
              </p>
            </span>
          ) : (
            '-'
          )}
        </p>
        <TooltipIconComponent
          color={theme.colors['gray-45']}
          content="The total consumption during the selected period with the evolution of the consumption compared to the baseline period."
        />
      </div>
    </SankeyButton>
  );
};
export default BuildingSankeyButton;
