import { ImpactWrapper } from '@components/wrappers/ImpactWrapper';
import { UnitTypeEnum } from 'enums/UnitTypeEnum';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getReadableUnitByName } from 'utils/formatters/units/getReadableUnitByName';

interface SavingsContainerProps {
  isLoading: boolean;
  savingsInEuro: number;
  totalSavings: number;
  utilityType?: string;
  enableNumericColorVisualization?: boolean;
}
const SavingsContainer: FC<SavingsContainerProps> = ({
  isLoading,
  savingsInEuro,
  totalSavings,
  utilityType,
  enableNumericColorVisualization,
}) => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardTitle
        title={t('projectsDetailsPage.savings')}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
      />

      <div className="flex justify-between mt-4 gap-4">
        <ImpactWrapper
          label={'Total in €'}
          value={savingsInEuro}
          endAddorment={'€'}
          isLoading={isLoading}
          data-testid="savings-total-euro"
          tooltip="The energy cost that was saved by implementing this project from the moment the project was live until today."
          numericColorVisualization={{ reverse: true }}
          enableNumericColorVisualization={enableNumericColorVisualization}
        />
        <ImpactWrapper
          label={'Total'}
          value={totalSavings}
          endAddorment={getReadableUnitByName(
            utilityType ?? UnitTypeEnum.UNDEFINED
          )}
          isLoading={isLoading}
          data-testid="savings-total"
          tooltip="The amount of energy that was saved by implementing this project from the moment the project was live until today."
          numericColorVisualization={{ reverse: true }}
          enableNumericColorVisualization={enableNumericColorVisualization}
        />
      </div>
    </CardContainer>
  );
};
export default SavingsContainer;
