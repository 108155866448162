import {
  Listbox,
  ListboxButton,
  ListboxOption,
  Transition,
} from '@headlessui/react';
import {
  type ButtonHTMLAttributes,
  type FC,
  Fragment,
  type ReactNode,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from 'react-icons/io';
import { CustomListboxOptions } from '../styled';
import { FErrorLabel } from 'components/form/FErrorLabel';
import { type InputAddormentProps } from '@components/inputs/InputAddornment/InputAddorment';
import { addInputErrorClasses } from 'utils/handleFormErrorStyle';
import { usePopper } from 'hooks/usePopper';
import { LoadingSpinner } from '@components/loadingSpinner';

export interface SingleSelectInputProps
  extends InputAddormentProps,
    Omit<
      ButtonHTMLAttributes<HTMLButtonElement>,
      'name' | 'children' | 'onChange'
    > {
  options: DropdownOptions[];
  defaultValue?: string | number;
  placeholder?: string;
  dropdownClassName?: string;
  getSelectedName?: (value: DropdownOptions) => ReactNode;
  value?: string | number;
  onChange?: (value: string | number | undefined) => void;
  disabledOptions?: string[];
  componentRender?: (value: string | number | undefined) => ReactNode;
  multiple?: boolean;
  maxWidth?: string;
  removable?: boolean;
  'data-testid'?: string;
  isFetching?: boolean;
}
export interface DropdownOptions {
  id?: string | number;
  value: string | number;
  name: string | undefined;
  startIcon?: ReactNode;
  dropdownOptions?: { name?: string; component?: ReactNode };
}

const SingleSelectInput: FC<SingleSelectInputProps> = forwardRef(
  (
    {
      id,
      error,
      options,
      defaultValue,
      dropdownClassName,
      placeholder,
      value,
      getSelectedName,
      onChange,
      componentRender,
      maxWidth,
      disabledOptions = [],
      disabled,
      isFetching,
      removable = true,
      ...props
    },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [trigger, container] = usePopper({
      placement: 'bottom-end',
      modifiers: [{ name: 'applyStyles', options: { offset: [0, 10] } }],
    });
    const handleDropDown = (): void => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOnChange = (value: string | number): void => {
      onChange?.(value);
    };

    const selectedOption = useMemo(
      () => options?.find((option) => option?.value === value),
      [options, value]
    );
    return (
      <div className={`flex-1 max-w-[${maxWidth ?? 'none'}]`}>
        <Listbox
          value={value}
          onChange={handleOnChange}
          ref={trigger}
          disabled={disabled}
        >
          <div className="relative w-full bg-white">
            <ListboxButton
              id={id}
              className={`relative w-full flex flex-1 items-center self-stretch px-3 py-2 border rounded-lg h-11 focus-within:border-primary-light
              ${error ? addInputErrorClasses(error) : 'border-gray-30'}
              `}
              onClick={handleDropDown}
              {...props}
            >
              <span className="flex items-center gap-2 justify-between w-full">
                <span className="flex items-center gap-2 flex-1 min-w-0">
                  {selectedOption && getSelectedName ? (
                    getSelectedName(selectedOption)
                  ) : (
                    <>
                      {componentRender ? (
                        componentRender(value)
                      ) : (
                        <>
                          {!selectedOption && (
                            <span className="text-gray-400">{placeholder}</span>
                          )}
                          {selectedOption?.startIcon}
                          <span
                            className={`text-gray-500 flex flex-1 justify-between items-center min-w-0`}
                          >
                            <span className="truncate">
                              {selectedOption?.name}
                            </span>
                            {selectedOption?.name && removable && (
                              <span>
                                <IoIosClose
                                  size={22}
                                  className="cursor-pointer text-primary"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleOnChange?.('');
                                  }}
                                />
                              </span>
                            )}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </span>
                {isDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <CustomListboxOptions
                static
                data-testid={
                  props['data-testid'] && `${props['data-testid']}-ul`
                }
                className={`z-50 ${dropdownClassName ?? ''}`}
                ref={container}
              >
                {isFetching ? (
                  <div className="flex items-center gap-4 px-4 py-2">
                    <LoadingSpinner />
                    <span>{'Loading'}</span>
                  </div>
                ) : options?.length === 0 ? (
                  <div className="flex items-center gap-4 px-4 py-2">
                    No data found
                  </div>
                ) : (
                  options?.map((option, index) => {
                    const isDisabled =
                      disabledOptions?.length > 0 &&
                      disabledOptions?.some(
                        (disabledValue) => option?.value === disabledValue
                      );
                    return (
                      <ListboxOption
                        key={option?.id ?? index}
                        className={({ focus }) =>
                          `relative cursor-default select-none z-40 ${
                            focus
                              ? 'bg-primary-lighter font-bold text-gray-60'
                              : 'text-gray-50 bg-white'
                          }`
                        }
                        disabled={isDisabled}
                        value={option?.value}
                        data-testid="select-option"
                        data-value={option?.value}
                      >
                        {({ selected, disabled }) => (
                          <div
                            className={`flex items-center justify-between gap-2 px-3 py-3 pr-4 cursor-pointer ${
                              selected ? 'bg-primary-lighter' : ''
                            }`}
                            onClick={handleDropDown}
                          >
                            <div className="flex min-w-0 gap-2.5 items-center">
                              {option.startIcon && (
                                <span className="w-6 h-6 inline-block">
                                  {option.startIcon}
                                </span>
                              )}

                              <span
                                className={`inline-block truncate ${
                                  disabled ? 'text-gray-30' : ''
                                } ${selected ? 'font-medium' : 'font-normal'}`}
                              >
                                {componentRender
                                  ? componentRender(option?.value)
                                  : option?.dropdownOptions?.name ??
                                    option.name}
                              </span>
                            </div>
                          </div>
                        )}
                      </ListboxOption>
                    );
                  })
                )}
              </CustomListboxOptions>
            </Transition>
          </div>
        </Listbox>
        <FErrorLabel message={error?.message} />
      </div>
    );
  }
);
export default SingleSelectInput;
