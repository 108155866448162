import { DownloadIcon } from '@components/icons/DownloadIcon';
import { ToBuildingViewButton } from '@components/ToBuildingViewButton';
import { UtilityChip } from '@components/utilityChip';
import { type Meter } from 'interfaces/assets/Meters.interface';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';

interface DetailsHeaderProps {
  projectName?: string;
  mainBuildingName?: string;
  mainBuildingId?: number;
  meter?: Meter;
  referencePeriodStart?: string;
  referencePeriodEnd?: string;
  implementationPeriodStart?: string;
  implementationPeriodEnd?: string;
  isLoading: boolean;
  downloadHandler?: () => Promise<void>;
}

const DetailsHeader: FC<DetailsHeaderProps> = ({
  projectName,
  mainBuildingName,
  mainBuildingId,
  meter,
  referencePeriodStart,
  referencePeriodEnd,
  implementationPeriodStart,
  implementationPeriodEnd,
  downloadHandler,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  const calculateTrackingStartDate = (endDate: string | undefined): string => {
    const startDate = endDate ? new Date(endDate) : new Date();
    startDate.setDate(startDate.getDate() + 1);
    return startDate.toString();
  };
  const trackingStartDate = calculateTrackingStartDate(implementationPeriodEnd);

  return (
    <>
      <div className="flex flex-row">
        <div className="basis-9/12">
          <div className="flex-col">
            <div className="flex gap-4 items-center min-h-[44px]">
              {isLoading ? (
                <Skeleton
                  containerClassName="flex-1 min-w-[400px]"
                  height={'28px'}
                />
              ) : (
                <h1 className="max-w-[300px] laptop:max-w-[600px] desktop:max-w-[1000px] text-ellipsis overflow-hidden ...">
                  {projectName}
                </h1>
              )}

              {isLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={'28px'}
                  width={'100px'}
                  borderRadius={'20px'}
                />
              ) : (
                <UtilityChip utility={meter?.utility_type} />
              )}
            </div>
            <div className="mt-4 flex gap-2 text-base">
              {isLoading ? (
                <Skeleton width={'150px'} />
              ) : mainBuildingName ? (
                <>
                  <p>
                    {
                      <ToBuildingViewButton
                        name={mainBuildingName}
                        id={mainBuildingId}
                      />
                    }
                  </p>
                  <span>|</span>
                </>
              ) : null}
              {isLoading ? <Skeleton width={'150px'} /> : <p>{meter?.name}</p>}
            </div>
          </div>
        </div>
        <div className="flex flex-row basis-3/12 justify-end flex-1">
          {isLoading ? (
            <Skeleton
              containerClassName="flex-1 max-w-[300px]"
              height={'28px'}
            />
          ) : (
            <DownloadIcon
              className="cursor-pointer"
              onClick={downloadHandler}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="mt-4 flex gap-2">
          {isLoading ? (
            <Skeleton width={'100px'} />
          ) : (
            <p>{t('projectsDetailsPage.referencePeriod')}:</p>
          )}
          {isLoading ? (
            <Skeleton width={'150px'} />
          ) : (
            <p>
              {referencePeriodStart &&
                convertToReadableDate(referencePeriodStart, {
                  onlyDate: true,
                })}{' '}
              -{' '}
              {referencePeriodEnd &&
                convertToReadableDate(referencePeriodEnd, {
                  onlyDate: true,
                })}
            </p>
          )}
        </div>
        <div className="mt-4 flex gap-2">
          {isLoading ? (
            <Skeleton width={'100px'} />
          ) : (
            <p>{t('projectsDetailsPage.implementationPeriod')}:</p>
          )}
          {isLoading ? (
            <Skeleton width={'150px'} />
          ) : (
            <p>
              {implementationPeriodStart &&
                convertToReadableDate(implementationPeriodStart, {
                  onlyDate: true,
                })}{' '}
              -{' '}
              {implementationPeriodEnd &&
                convertToReadableDate(implementationPeriodEnd, {
                  onlyDate: true,
                })}
            </p>
          )}
        </div>
        <div className="mt-4 flex gap-2">
          {isLoading ? (
            <Skeleton width={'100px'} />
          ) : (
            <p>{t('projectsDetailsPage.trackingStartedOn')}:</p>
          )}
          {isLoading ? (
            <Skeleton width={'150px'} />
          ) : (
            <p>
              {convertToReadableDate(trackingStartDate, {
                onlyDate: true,
              })}
            </p>
          )}
        </div>
      </div>
    </>
  );
};
export default DetailsHeader;
