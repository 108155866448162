export enum UtilityTypeEnum {
  ELECTRICITY = 'ELECTRICITY',
  WATER = 'WATER',
  GAS = 'GAS',
  THERMAL_ENERGY = 'THERMAL_ENERGY',
  STEAM = 'STEAM',
  CONDENSATE = 'CONDENSATE',
}

export enum UtilityTypeToUnitEnum {
  ELECTRICITY = 'kWh',
  WATER = 'm³',
  GAS = 'kWh',
}
