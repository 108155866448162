import { type FC, forwardRef } from 'react';

import useChart, { parseChartDecimals } from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import { getReadableUnitByName } from 'utils/formatters/units/getReadableUnitByName';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';

interface ProjectMeasuredVsExpectedChartProps {
  unit?: string;
  dataGrouping?: [string, number[] | null][] | undefined;
  data?: ChangeTS[] | null;
  xAxisEvents?: Highcharts.XAxisEventsOptions | undefined;
}

interface SavingsOption {
  x: number;
  y: number;
  impactPerDay: number;
  expectedConsumption: number;
  actualConsumption: number;
  time: string;
}

const ProjectMeasuredVsExpectedChart: FC<ProjectMeasuredVsExpectedChartProps> =
  forwardRef(({ unit = '', dataGrouping, data, xAxisEvents }, _ref) => {
    const { defaultOptions, labelFormatter } = useChart({
      chartData: data,
      unit: getReadableUnitByName(unit),
      type: 'measured',
    });
    function pointFormatter(this: Highcharts.Point): string {
      const options = this.options as SavingsOption;
      const type = 'Savings';

      const timeString = `Time: <b>${convertToReadableDate(
        options.time
      )}</b><br/>`;
      const savingsLabel = `${type}: <b>${parseChartDecimals(
        options.impactPerDay ?? 0
      )} ${getReadableUnitByName(unit)}</b></br>`;

      const expectedConsumption = `Expected consumption: <b>${
        parseChartDecimals(options.expectedConsumption) ?? 0
      } ${getReadableUnitByName(unit)}</b></br>`;

      const actualConsumption = `Actual consumption: <b>${
        parseChartDecimals(options.actualConsumption) ?? 0
      } ${getReadableUnitByName(unit)}</b></br>`;

      return `${timeString}${savingsLabel}${expectedConsumption}${actualConsumption}`;
    }

    const { theme } = useTheme();

    const options: Highcharts.Options = {
      ...defaultOptions,
      xAxis: {
        events: {
          ...xAxisEvents,
        },
      },
      yAxis: [
        {
          labels: {
            formatter: labelFormatter,
          },
          title: {
            text: 'Energy Use',
          },
        },
        {
          title: {
            text: 'Result Baseline',
          },
          opposite: true,
        },
      ],
      series: [
        {
          name: 'Measured',
          type: 'column',
          color: theme.colors.tertiary,
          data: data?.map((ts, index) => ({
            x: new Date(ts.time).getTime(),
            y: ts.energy_use,
            impactPerDay: ts.savings,
            actualConsumption: ts.energy_use,
            expectedConsumption: ts.result_baseline,
            time: ts.time,
          })),
          dataGrouping: {
            approximation: 'sum',
            groupPixelWidth: 10,
            units: dataGrouping ?? [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          tooltip: {
            pointFormatter,
          },
          turboThreshold: data?.length,
          yAxis: 0,
        },
        {
          color: theme.colors.primary,
          type: 'line',
          name: 'Expected consumption',
          data: data?.map((ts, index) => [
            new Date(ts.time).getTime(),
            ts.result_baseline,
          ]),
          dataGrouping: {
            approximation: 'sum',
            groupPixelWidth: 10,
            units: dataGrouping ?? [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          yAxis: 0,
          turboThreshold: data?.length,
          enableMouseTracking: false,
        },
      ],
    };

    return <>{options && <HChart options={options} />}</>;
  });
export default ProjectMeasuredVsExpectedChart;
