// import { SadFaceIcon } from '@components/icons/FaceIcon';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import clsx from 'clsx';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
// import { type BuildingView } from 'interfaces/assets/Building.interface';
import { CardContainer } from 'layout/Card';
import { type ActionItem } from 'pages/BuildingViewPage/BuildingViewPage';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  convertToPercentageNumber,
  formatDecimalSeparator,
} from 'utils/formatters/number/numberUtils';

interface BuildingOverallPerformanceProps {
  building?: any;
  isFetching?: boolean;
  renewableEnergy?: number;
  setIsActionRequired: React.Dispatch<React.SetStateAction<ActionItem[]>>;
}

const BuildingOverallPerformance: FC<BuildingOverallPerformanceProps> = ({
  building,
  isFetching,
  renewableEnergy,
  setIsActionRequired,
}) => {
  const { t } = useTranslation();

  const targetSavings2050 = building?.target_savings_2050;
  const organizationalImpact = building?.organizational_impact;
  const energyIntensity = building?.energy_intensity_kwh;
  const lastYear = new Date().getFullYear() - 1;
  return (
    <div className="flex flex-col gap-2.5 flex-1 h-full">
      <h3 className="font-bold uppercase">Overall performance</h3>
      <CardContainer
        style={{ flex: 1, justifyContent: 'space-between' }}
        gap={4}
      >
        <OverallPerformanceItem
          title={`Energy intensity ${lastYear}`}
          value={energyIntensity}
          unit={BuildingUnitEnum.kwh}
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.energyIntensity')}
          effectivenessThreshold={undefined}
          name={'energy_intensity'}
          setIsActionRequired={setIsActionRequired}
        />
        <OverallPerformanceItem
          title={'Renewable Energy'}
          value={
            renewableEnergy !== undefined
              ? convertToPercentageNumber(renewableEnergy)
              : undefined
          }
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.renewableEnergy')}
          effectivenessThreshold={undefined}
          name={'renewable_energy'}
          setIsActionRequired={setIsActionRequired}
        />
        <OverallPerformanceItem
          title={'Savings target 2050'}
          value={
            targetSavings2050
              ? convertToPercentageNumber(targetSavings2050)
              : undefined
          }
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.savingsTarget')}
          effectivenessThreshold={15}
          decimal={2}
          name={'savings_target_2050'}
          setIsActionRequired={setIsActionRequired}
        />
        <OverallPerformanceItem
          title={'Organizational impact'}
          value={
            organizationalImpact
              ? convertToPercentageNumber(organizationalImpact)
              : undefined
          }
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.organizationalImpact')}
          effectivenessThreshold={15}
          decimal={2}
          name={'organizational_impact'}
          setIsActionRequired={setIsActionRequired}
        />
      </CardContainer>
    </div>
  );
};

enum BuildingUnitEnum {
  percentage = '%',
  kwh = 'kWh/m²',
}

const OverallPerformanceItem: FC<{
  title: string;
  value?: number;
  unit?: BuildingUnitEnum;
  isFetching?: boolean;
  tooltipContent: string;
  effectivenessThreshold?: number;
  decimal?: number;
  name: string;
  setIsActionRequired: React.Dispatch<React.SetStateAction<ActionItem[]>>;
}> = ({
  title,
  value,
  unit = BuildingUnitEnum.percentage,
  isFetching,
  tooltipContent,
  decimal = 0,
  effectivenessThreshold,
  name,
  setIsActionRequired,
}) => {
  const { theme } = useTheme();
  // const shouldUseEffectiveness = value && effectivenessThreshold;
  // const effectiveness = (value ?? 0) < (effectivenessThreshold ?? 0);

  // useMemo(() => {
  //   if (value && effectivenessThreshold) {
  //     const action = value > effectivenessThreshold;
  //     if (shouldUseEffectiveness) {
  //       setIsActionRequired((prev) => {
  //         const updatedArray = prev.filter((item) => item.name !== name);
  //         return [
  //           ...updatedArray,
  //           {
  //             name,
  //             isActionRequired: action,
  //           },
  //         ];
  //       });
  //     }
  //   }
  // }, [value, effectivenessThreshold]);

  return (
    <div
      className={clsx(
        'flex flex-col gap-2 px-4 py-2 bg-gray-10 rounded-lg border ',
        // shouldUseEffectiveness
        //   ? effectiveness
        //     ? 'border-success'
        //     : 'border-error'
        //   :
        'border-gray-10'
      )}
    >
      {isFetching ? (
        <Skeleton height={24} containerClassName="flex-1" />
      ) : (
        <div className="flex justify-between items-center">
          <p className="text-base">{title}</p>
          <TooltipIconComponent
            content={tooltipContent}
            color={theme.colors['gray-45']}
          />
        </div>
      )}

      <div className="flex justify-between items-center">
        {isFetching ? (
          <Skeleton height={30} containerClassName="flex-1" />
        ) : (
          <p className="text-2xl font-bold" style={{ fontSize: 28 }}>
            {value !== undefined
              ? formatDecimalSeparator({
                  value,
                  decimalScale: decimal,
                })
              : '-'}
            {value !== undefined &&
              (unit === BuildingUnitEnum.kwh ? (
                <span className="text-base">{` ${BuildingUnitEnum.kwh}`}</span>
              ) : (
                '%'
              ))}
          </p>
        )}

        {/* {isFetching ? (
          <></>
        ) : (
          shouldUseEffectiveness &&
          !effectiveness && <SadFaceIcon className="mr-[-5px]" />
        )} */}
      </div>
    </div>
  );
};
export default BuildingOverallPerformance;
