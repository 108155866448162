import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import i18next from 'i18next';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { numericFormatter } from 'react-number-format';
import {
  getUtilityIcon,
  getUtilityTypeUnit,
} from 'utils/utilityTypeUtils/utilityTypeUtils';
import { TableProgressBar } from '../components';
import { ToBuildingViewButton } from '@components/ToBuildingViewButton';
import Tippy from '@tippyjs/react';

interface ProjectsColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetProjectsColumns = ({
  createTableActions,
}: {
  createTableActions: (id: number) => ReactNode;
}): ProjectsColumnsReturnType => {
  const columnHelper = createColumnHelper<any>();
  const { t } = useTranslation();

  const columns = [
    columnHelper.accessor('name', {
      header: () => t('common.name'),
      cell: (info) =>
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        info.renderValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('building_name', {
      header: () => 'Asset name',
      cell: (info) => {
        const buildingName = info.getValue();
        const buildingId = info.row.original.building_id;
        return (
          <Tippy content={buildingName} disabled={!buildingName}>
            <span className="truncate block overflow-hidden phone:max-w-[100px] laptop:max-w-[130px] desktop:max-w-[200px]">
              {buildingName ? (
                <ToBuildingViewButton name={buildingName} id={buildingId} />
              ) : (
                '-'
              )}
            </span>
          </Tippy>
        );
      },
    }),
    columnHelper.accessor('investment_cost', {
      header: () => 'Investment cost',
      cell: (info) =>
        numericFormatter((info.getValue() ?? 0).toFixed(2), {
          decimalSeparator: ',',
          thousandSeparator: '.',
          decimalScale: 0,
          suffix: ' €',
        }),
    }),
    columnHelper.accessor('savings_euro', {
      header: () => (
        <div className="flex items-center gap-2">
          <span>{i18next.t('common.savings')}</span>
          <TooltipIconComponent
            content={i18next.t('projectsPage.savingsTooltip')}
          />
        </div>
      ),
      cell: (info) =>
        numericFormatter((info.getValue() ?? 0).toString(), {
          decimalSeparator: ',',
          thousandSeparator: '.',
          decimalScale: 0,
          suffix: ' €',
        }),
    }),
    columnHelper.accessor('savings_total', {
      header: () => 'Savings',
      cell: (info) =>
        numericFormatter((info.getValue() ?? 0).toString(), {
          decimalSeparator: ',',
          thousandSeparator: '.',
          decimalScale: 0,
          suffix: ` ${getUtilityTypeUnit(info.row.original?.utility_type)}`,
        }),
    }),
    columnHelper.accessor('utility_type', {
      header: () => i18next.t('common.utilityType'),
      cell: (info) => {
        return getUtilityIcon(info.getValue());
      },
    }),
    columnHelper.accessor('savings_rel', {
      id: 'savings_rel',
      header: () => (
        <div className="flex items-center gap-2">
          <span>{i18next.t('common.savingsTarget')}</span>
          <TooltipIconComponent
            content={i18next.t('projectsPage.savingsTargetTooltip')}
          />
        </div>
      ),
      cell: (info) => {
        const values = info.row.original;
        const completed = parseFloat(
          numericFormatter(((values?.savings_rel ?? 0) * 100).toString(), {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 1,
          })
        );
        const maxCompleted = parseFloat(
          numericFormatter(((values?.savings_target ?? 0) * 100).toString(), {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 1,
          })
        );
        return (
          <TableProgressBar
            completed={completed}
            maxCompleted={maxCompleted}
            maxWidth={120}
          />
        );
      },
    }),
    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info.row.original.id),
      size: 15,
    }),
  ];

  return {
    columns,
  };
};

export default useGetProjectsColumns;
