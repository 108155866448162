import { UnitTypeEnum } from 'enums/UnitTypeEnum';

export const getReadableUnitByName = (unit: string): string => {
  if (unit === UnitTypeEnum.UNDEFINED) return '';
  return UnitTypeEnum[unit as keyof typeof UnitTypeEnum];
};

export const parseUnitType = (unit?: string): string => {
  if (!unit) return '';
  if (unit === 'm3') return 'm³';
  return unit;
};
