/* eslint-disable @typescript-eslint/restrict-template-expressions */
// import { type BuildingView } from 'interfaces/assets/Building.interface';
import { CardContainer } from 'layout/Card';
import { type FC, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
// import { BookMarkTag } from '../BookMarkTag';
import { BuildingAttentionLevel } from '../BuildingAttentionLevel';
import { AttentionLevels } from '../BuildingAttentionLevel/BuildingAttentionLevel';
import BuildingMap from './BuildingMap';
import { formatDecimalSeparator } from 'utils/formatters/number/numberUtils';
import { type ActionItem } from 'pages/BuildingViewPage/BuildingViewPage';

interface BuildingCardProps {
  asset?: any;
  isFetching?: boolean;
  isActionRequired: ActionItem[];
}

const BuildingCard: FC<BuildingCardProps> = ({
  asset,
  isFetching,
  isActionRequired,
}) => {
  const [attention, setAttention] = useState(AttentionLevels.LOW);
  const parsedAddress = useMemo(() => {
    const address = asset?.address;
    if (address) {
      return `${address.street} ${address.number}, ${address.city} ${
        address.province ?? ''
      } 
      (${address.country_iso?.toUpperCase()})`;
    }
    return 'No address found';
  }, [asset]);

  useEffect(() => {
    const hasActionRequired = isActionRequired.some(
      (item) => item.isActionRequired
    );
    setAttention(
      hasActionRequired ? AttentionLevels.HIGH : AttentionLevels.LOW
    );
  }, [isActionRequired]);

  return (
    <div className="w-9/12 mt-[31px] flex">
      <CardContainer style={{ flex: 1 }}>
        <div className="flex gap-4">
          <div className="min-w-[300px] flex rounded-lg overflow-hidden">
            <BuildingMap
              lat={asset?.address?.latitude ?? 0}
              long={asset?.address?.longitude ?? 0}
              isFetching={isFetching}
            />
          </div>

          <div className="flex flex-col gap-5 w-full">
            <div className="flex relative">
              {isFetching ? (
                <Skeleton height={30} containerClassName={'w-4/6'} />
              ) : (
                <h1>{asset?.name}</h1>
              )}
              {/* <BookMarkTag isFetching={isFetching} /> */}
            </div>
            <BuildingAttentionLevel
              attention={attention}
              isFetching={isFetching}
            />
            <div className="flex flex-col pt-5 border-t border-gray-10 gap-5">
              <AddressItem
                title="Address"
                content={parsedAddress}
                id="address"
                isFetching={isFetching}
              />
              <AddressItem
                title="Surface area"
                content={formatDecimalSeparator({
                  value: asset?.surface_area,
                  suffix: ` m²`,
                })}
                id="surface-area"
                isFetching={isFetching}
              />
              <AddressItem
                title="Function"
                content={asset?.function ?? 'None'}
                id="function"
                isFetching={isFetching}
              />
            </div>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

const AddressItem: FC<{
  title: string;
  content: string;
  id: string;
  isFetching?: boolean;
}> = ({ title, content, id, isFetching }) => {
  return (
    <div className="flex flex-col gap-1">
      {isFetching ? (
        <Skeleton height={28} containerClassName="flex-1" />
      ) : (
        <p id={id} className="text-lg font-bold">
          {content}
        </p>
      )}
      {isFetching ? (
        <Skeleton height={22} containerClassName="flex-1" />
      ) : (
        <label htmlFor={id} className="text-base">
          {title}
        </label>
      )}
    </div>
  );
};
export default BuildingCard;
