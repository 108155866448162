import { type FC, forwardRef } from 'react';
import useChart, { parseChartDecimals } from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { getReadableUnitByName } from 'utils/formatters/units/getReadableUnitByName';

interface SavingsOption {
  x: number;
  y: number;
  impactPerDay: number;
  time: string;
}

interface SavingsChartProps {
  unit?: string;
  data?: ChangeTS[] | null;
  xAxisEvents?: Highcharts.XAxisEventsOptions | undefined;
}

const SavingsChart: FC<SavingsChartProps> = forwardRef(
  ({ unit = '', data, xAxisEvents }, ref) => {
    const { defaultOptions, labelFormatter } = useChart({
      chartData: data,
      unit: getReadableUnitByName(unit),
      type: 'excess',
    });

    const { theme } = useTheme();

    function pointFormatter(this: Highcharts.Point): string {
      const options = this.options as SavingsOption;
      const type = 'Savings';

      const timeString = `Time: <b>${convertToReadableDate(
        options.time
      )}</b><br/>`;
      const consumptionLabel = `${type}: <b>${parseChartDecimals(
        this.y ?? 0
      )} ${getReadableUnitByName(unit)}</b></br>`;

      return `${timeString}${consumptionLabel}`;
    }

    const options: Highcharts.Options = {
      ...defaultOptions,
      xAxis: {
        events: {
          ...xAxisEvents,
        },
      },
      yAxis: [
        {
          labels: {
            formatter: labelFormatter,
          },
          title: {
            text: 'Savings',
          },
        },
      ],

      series: [
        {
          name: 'Savings',
          type: 'column',
          color: theme.colors.tertiary,
          data: data?.map((ts) => ({
            x: new Date(ts.time).getTime(),
            y: ts.savings,
            impactPerDay: ts.savings,
            time: ts.time,
          })),
          dataGrouping: {
            groupPixelWidth: 10,
            units: [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          tooltip: {
            pointFormatter,
          },
          turboThreshold: data?.length,
          yAxis: 0,
        },
      ],
    };

    return <>{options && <HChart options={options} />}</>;
  }
);
export default SavingsChart;
