import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { type CellContext } from '@tanstack/react-table';
import { useListChanges } from 'api/changes/changesServices';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import useDisclosure from 'hooks/useDisclosure';
import { useServerSideTableSorting } from 'hooks/useServerSideTableSorting';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
// import { ChangeSideMenu } from 'pages/Changes/ChangesListPage/components/ChangeSideMenu';
import { type FC, type ReactNode, useMemo, useState } from 'react';
import { UpdateStatusModal } from '../../modal/UpdateStatusModal';
import useGetChangesTableColumns from '../../hooks/usetGetChangesTableColumns/useGetChangesTableColumns';
import { useNavigate } from 'react-router-dom';
import { type Change } from 'interfaces/change/ChangeData.interface';

interface ChangeTableProps {
  columnVisibility: {
    impact_average_diff_euro: boolean;
    impact_average_diff: boolean;
  };
  query?: string;
  filters?: {
    utilityType?: string;
    statusQuery?: string;
    groupQuery?: number;
    assetQuery?: number;
  };
}

const ChangeTable: FC<ChangeTableProps> = ({
  columnVisibility,
  query,
  filters,
}) => {
  const [changeId, setChangeId] = useState<number | undefined>();

  const { hasFeatureFlagPermission } = useFeatureFlag();
  const { handleSortChange, orderBy } = useServerSideTableSorting({
    addSortToUrlParams: true,
  });
  const navigate = useNavigate();

  const {
    data: changesList,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useListChanges({
    query,
    utilityType: filters?.utilityType,
    status: filters?.statusQuery,
    group: filters?.groupQuery,
    asset: filters?.assetQuery,
    orderBy,
  });

  const totalDBRowCount = changesList?.pages?.[0]?.total ?? 0;

  const flatData = useMemo(
    () =>
      changesList?.pages?.flatMap(
        (page: any) => page?.changes_detected ?? []
      ) ?? [],
    [changesList]
  );

  // const { isOpen, handleClose, handleOpen } = useDisclosure();
  const {
    isOpen: isUpdateStatusOpen,
    handleClose: handleUpdateStatusClose,
    handleOpen: handleUpdateStatusOpen,
  } = useDisclosure();

  const createTableActions = (info: CellContext<any, any>): ReactNode => {
    const id: number = info?.row?.original?.change_detection_id;
    const actions = hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.get)
      ? [
          {
            name: 'Details',
            isAnchor: true,
            linkTo: `details/${id}`,
          },
          // {
          //   name: 'Summary',
          //   onClick: () => {
          //     setChangeId(id);
          //     handleOpen();
          //   },
          // },

          hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.patch_status)
            ? {
                name: 'Update status',
                onClick: () => {
                  setChangeId(id);
                  handleUpdateStatusOpen();
                },
              }
            : null,
        ]
      : [];
    return hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.get) ? (
      <TableActions actions={actions} />
    ) : (
      <></>
    );
  };

  const goToDetails = (id?: number): void => {
    if (id) navigate(`details/${id}`);
  };

  const handleRowClick = (row?: Change): void => {
    setChangeId(row?.change_detection_id);
    goToDetails(row?.change_detection_id);
  };

  const { columns } = useGetChangesTableColumns({ createTableActions });

  return (
    <>
      <Table
        columns={columns}
        data={flatData}
        enableRowSelection={true}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        onClickRow={
          hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.get)
            ? handleRowClick
            : undefined
        }
        state={{ columnVisibility }}
        rowEstimateSize={100}
        handleSortChange={handleSortChange}
        manualSorting={true}
      />
      {/* <ChangeSideMenu
        isOpen={isOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        changeId={changeId}
      /> */}
      <UpdateStatusModal
        changeId={changeId ? changeId.toString() : undefined}
        isOpen={isUpdateStatusOpen}
        handleOpen={handleUpdateStatusOpen}
        handleClose={handleUpdateStatusClose}
      />
    </>
  );
};
export default ChangeTable;
