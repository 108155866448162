import { type FC, forwardRef } from 'react';

import useChart, { parseChartDecimals } from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import { parseUnitType } from 'utils/formatters/units/getReadableUnitByName';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';

interface BuildingMeterChartProps {
  unit?: string;
  dataGrouping?: [string, number[] | null][] | undefined;
  data?: ChangeTS[] | null;
  xAxisEvents?: Highcharts.XAxisEventsOptions | undefined;
}

interface SavingsOption {
  x: number;
  y: number;
  impactPerDay: number;
  expectedConsumption: number;
  actualConsumption: number;
  time: string;
}

const BuildingMeterChart: FC<BuildingMeterChartProps> = forwardRef(
  ({ unit = '', dataGrouping, data, xAxisEvents }, _ref) => {
    const { theme } = useTheme();
    const { defaultOptions, labelFormatter } = useChart({
      chartData: data,
      unit: parseUnitType(unit),
      type: 'measured',
    });
    function pointFormatter(this: Highcharts.Point): string {
      const options = this.options as SavingsOption;

      const timeString = `Time: <b>${convertToReadableDate(
        options.time
      )}</b><br/>`;
      const actualConsumption = `Actual consumption: <b>${
        parseChartDecimals(this.y) ?? 0
      } ${parseUnitType(unit)}</b></br>`;

      return `${timeString}${actualConsumption}`;
    }

    const options: Highcharts.Options = {
      ...defaultOptions,
      chart: {
        ...defaultOptions.chart,
        events: {
          ...defaultOptions.chart?.events,
          load() {
            const endDate = new Date().getTime();
            const startDate = endDate - 365 * 24 * 3600 * 1000; // 1 year back
            this.xAxis[0].setExtremes(startDate, endDate);
          },
        },
      },
      xAxis: {
        events: {
          ...xAxisEvents,
        },
      },

      yAxis: [
        {
          labels: {
            formatter: labelFormatter,
          },
          title: {
            text: 'Energy Use',
          },
        },
      ],
      series: [
        {
          name: 'Measured',
          type: 'column',
          color: theme.colors.tertiary,
          data: data?.map((ts, index) => ({
            x: new Date(ts.time).getTime(),
            y: ts.energy_use,
            impactPerDay: ts.savings,
            actualConsumption: ts.energy_use,
            expectedConsumption: ts.result_baseline,
            time: ts.time,
          })),
          dataGrouping: {
            approximation: 'sum',
            groupPixelWidth: 10,
            units: dataGrouping ?? [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          tooltip: {
            pointFormatter,
          },
          turboThreshold: data?.length,
          yAxis: 0,
        },
      ],
    };

    return <>{options && <HChart options={options} />}</>;
  }
);
export default BuildingMeterChart;
