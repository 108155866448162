import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios';
import {
  type BuildingSankeyResponse,
  type BuildingViewResponse,
} from 'interfaces/assets/Building.interface';
import { type TimeSeries } from 'interfaces/change/ChangeData.interface';

export const useFetchBuildingView = (
  id?: string
): UseQueryResult<BuildingViewResponse> => {
  return useQuery<BuildingViewResponse>({
    queryKey: ['fetchBuildingDetails', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`asset/building/${id}/details`);
      return response.data;
    },
  });
};

export const useFetchBuildingSankey = (
  id?: string,
  utilityType?: string,
  timeFilter?: string
): UseQueryResult<Partial<BuildingSankeyResponse>> => {
  return useQuery<Partial<BuildingSankeyResponse>>({
    queryKey: ['fetchBuildingDetails', id, utilityType, timeFilter],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(
        `asset/building/${id}/energy_overview`,
        { params: { utility_type: utilityType, time_filter: timeFilter } }
      );
      return response.data;
    },
    placeholderData: (prev, query) => {
      const prevParams = query?.queryKey?.[3];

      if (prevParams !== timeFilter) {
        return { asset_buttons: [] };
      }

      return { asset_buttons: prev?.asset_buttons };
    },
  });
};

export const useFetchMeterTimeseries = (
  id?: number
): UseQueryResult<TimeSeries> => {
  return useQuery<TimeSeries>({
    queryKey: ['fetchMeterTimeseries', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`meter/timeseries/${id}`);
      return response.data;
    },
  });
};
